import React, {useRef,useState} from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {AnimatePresence, motion} from 'framer-motion'
import {contactFormHolder} from './ContactForm.module.scss'

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup
    .string('Enter your name')
    .required('Your name is required'),
  subject: yup
    .string('Enter a subject')
    .required('A subject is required'),
  message: yup
    .string('Enter a message')
    .required('A message is required'),
});

const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    htmlFontSize: 10,
    fontFamily: ['Montserrat']
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#1e3d7c',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  shape: {
    borderRadius:0
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor:'#ffffff',
        marginBottom: '1.5rem',
        '&$focused': {
          backgroundColor:'#ffffff'
        },
        '&:hover': {
          backgroundColor:'#fafafa'
        },
        '&$error': {
          marginBottom:'0.5rem'
        },
        helperText: {
          marginBottom:'1rem'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        marginBottom:'1rem'
      }
    }
  }
});

const variants = {
  pre:{
    opacity:0,
    y:'-2rem'
  },
  visible: {
    opacity:1,
    y:0
  },
  post: {
    opacity:0,
    y:'2rem'
  }
}

const Contact = ({query, setLoading, setResult}) => {


  const formRef = useRef()
  
  const type = query.type

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }


  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      subject: type ? `${type}` : 'General website enquiry',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true)

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "ContactForm",
          "name": values.name,
          "email": values.email,
          "subject": values.subject,
          "message": values.message,
        })
      }).then(() => {
        setLoading(false)
        setResult('success')
      })
      .catch(error => {
        setResult('error')
        setLoading(false)
        alert(error)
      })
    },
  });



  return (
    <motion.div key="contactForm" variants={variants} initial="pre" animate="visible" exit="post" >
    <form      
      onSubmit={formik.handleSubmit}
      name="ContactForm"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      id="mailing-list-form"
      ref={formRef}>
        <input type="hidden" name="form-name" value="ContactForm" />
        <TextField
          id="name"
          name="name"
          label="Your Name"
          type="name"
          fullWidth
          variant="filled"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          fullWidth
          variant="filled"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          id="subject"
          name="subject"
          label="Subject"
          variant="filled"
          fullWidth
          value={formik.values.subject}
          onChange={formik.handleChange}
          error={formik.touched.subject && Boolean(formik.errors.subject)}
          helperText={formik.touched.subject && formik.errors.subject}
        />
        <TextField
          id="message"
          name="message"
          label="Message"
          variant="filled"
          multiline
          rows={8}
          fullWidth
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
        <Button color="primary" variant="outlined"  type="submit" size="large">
          Submit
        </Button>
    </form>
    </motion.div>
  );
};

export default function ContactForm({query}) {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const resetForm = () => {
    setLoading(false)
    setResult(null)
  }

  return (
    <div className={contactFormHolder} id="contact-form">
      <h2>Contact Form</h2>
      <ThemeProvider theme={theme}>
      <AnimatePresence exitBeforeEnter>
      { (result===null) ? 
        <Contact query={query} setResult={setResult} setLoading={setLoading} />
        : 
        <motion.div key="results" className="resultsHolder" variants={variants} initial="pre" animate="visible" exit="post">
          {
            (result==='success') ?
            <div>
            <p>Thank you for your message.</p>
            <p>
            <Button
            color="primary"
            variant="outlined"
            type="submit"
            size="large"
            disabled={loading}
            onClick={()=>{ resetForm() }}
            >
            Send another message
            </Button>
            </p>
            </div>
            :
            <div>
              <p>Sorry, something went wrong:</p>
              <p><Button disabled={loading} size="large" variant="outlined" onClick={()=>{ resetForm() }}>Try again</Button></p>
            </div>
          }
        </motion.div>
      }
      </AnimatePresence>
      </ThemeProvider>
    </div>
  )
}

