import React from 'react'

import Seo from "../components/layout/Seo"
import { StaticImage } from "gatsby-plugin-image";
import { heroHolder } from "../components/components/PageElements.module.scss"

import { SuperTitle, HeroTitle } from '../components/components/PageElements'

import { MotionConfig} from 'framer-motion'
import queryString from 'query-string'

import Spain from '../images/pages/contact/Spain.svg'
import UkFlag from '../images/pages/contact/UkFlag.svg'

import ContactForm from '../components/components/ContactForm'
import { contactHolder } from "./Contact.module.scss"

export default function ContactPage({location}) {

  const query = queryString.parse(location.search)


  return (
    <MotionConfig transition={{duration:0.75, type:"tween"}}>
    <article className={contactHolder} >
      <Seo title="Contact Pygmalion Capital Ltd" description="Dedicated Hotel Investing" />
      <section className={heroHolder}>
        <div className="lrHolder">
          <div className="left">
            <StaticImage
              src="../images/pages/contact/contact-hero.jpg"
              alt="Contact Pygmalion Capital"
              quality={80}
              width={800}
              className="roundedCorners"
            />
          </div>
          <div className="right contactContent">
            <div className="inner">
              <SuperTitle title="Get in touch" inView={true}/>
              <HeroTitle>Contact Pygmalion</HeroTitle>
              <div className="intro"><p>To get in touch with Pygmalion Capital, request more information about the company or to learn about our opportunities, please contact us using the following details or the contact form below.</p></div>
              <div className="addresses lrHolder">
                <div className="address left">
                  <UkFlag />
                  <p>Pygmalion Capital Advisers LLP<br />
                  50 Sloane Avenue<br />
                  London<br />
                  SW3 3DD<br />
                  United Kingdom</p>
                  <p><a href="tel:00442075091796">+44 (0)20 7509 1796</a></p>
                  <p><a href="mailto:info@pygmalioncapital.com">info@pygmalioncapital.com</a></p>
                </div>

                <div className="address right">
                  <Spain />
                  <p>Pygmalion Capital Advisers Iberia, S.L<br />
                  Avinguda Diagonal 640<br />
                  6th floor, Office 666<br />
                  08017 Barcelona<br />
                  Spain</p>
                  <p><a href="tel:0034935454175">+34 93 545 4175</a></p>
                  <p><a href="mailto:info@pygmalioncapital.com">info@pygmalioncapital.com</a></p>
                </div>
              </div>
              <ContactForm query={query} />
            </div>
          </div>
        </div>
      </section>

    </article>
    </MotionConfig>
  )
}
